import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Header from "../../Component/Header/Header";
import Footrer from "../../Component/Footer/Footrer";
import "./contactUs.css";
import { Helmet } from "react-helmet";
import { FaLocationDot } from "react-icons/fa6";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const { name, email, mobile } = formData;

    if (!name || !email || !mobile) {
      return "Please fill in all the fields.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }

    const mobileRegex = /^[0-9]{10}$/; // Assuming mobile numbers should be 10 digits
    if (!mobileRegex.test(mobile)) {
      return "Please enter a valid 10-digit mobile number.";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setMessage(validationError);
      return;
    }

    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/tmsApi/tmsData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMessage(result.msg);
        setFormData({ name: "", email: "", mobile: "" }); // Clear form fields
      } else {
        setMessage("Error: " + result.msg);
      }
    } catch (error) {
      setMessage("Network error: " + error.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const currentUrl = window.location.href;
  return (
    <>
      <div className="Contact_header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | Contact Us</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="container text_p">
          <p>
            Home <span style={{ marginLeft: "15px" }}>Contact Us</span>
          </p>
        </div>
      </div>
      <div className="wrapper wrapper_ZindexStyle">
        <div className="container">
          <div className="row main_contactUpage">
            <div className="col-lg-6">
              <div className="contactUs_page">
                <h4>CONTACT NOW</h4>
                <p>Stay @ Home and Get your free career counselling now.</p>
                <div className="inputs">
                  <Form.Floating className="mb-3">
                    <Form.Control
                      id="floatingInputCustom"
                      type="text"
                      placeholder="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputCustom">Name</label>
                  </Form.Floating>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      onChange={handleChange}
                      id="floatingInputCustom"
                      type="email"
                      placeholder="name@example.com"
                      name="email"
                      value={formData.email}
                    />
                    <label htmlFor="floatingInputCustom">Email address</label>
                  </Form.Floating>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      onChange={handleChange}
                      id="floatingInputCustom"
                      type="text"
                      placeholder="Mobile No."
                      name="mobile"
                      value={formData.mobile}
                    />
                    <label htmlFor="floatingInputCustom">Mobile No.</label>
                  </Form.Floating>
                </div>
                {message && (
                  <p
                    style={{
                      margin: "15px 0px",
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    {message}
                  </p>
                )}
                <button onClick={handleSubmit}>Submit</button>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contactUs_image">
                <img src="/Images/contact-img.jpg" alt="Contact-Us-Image" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="Adress">
                <h5>Adress</h5>
                <h6>Australia</h6>
                <ul>
                  <li>
                    Mobile No. : <span>0413180732</span>
                  </li>
                  <li>
                    Email : <span>contact@themigrationschool.com</span>
                  </li>
                </ul>
                <p>
                  <span>
                    <FaLocationDot />
                  </span>{" "}
                  10 Carter Street, Lidcombe NSW 2141
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Adress">
                <h5>Adress</h5>
                <h6>India</h6>
                <ul>
                  <li>
                    Mobile No. : <span>+91 8950546462</span>
                  </li>
                  <li>
                    Email : <span>contact@themigrationschool.com</span>
                  </li>
                </ul>
                <p>
                  <span>
                    <FaLocationDot />
                  </span>{" "}
                  First Floor,Besides Sadar Thana,Delhi Rohtak Corridor,opp
                  metro pillar no 830, Bahadurgarh,Haryana -124507
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default ContactUs;
