import React, { useState } from "react";
import "./home.css";
import Header from "../../Component/Header/Header";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import { HiUserGroup } from "react-icons/hi2";
import { FaSyncAlt } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const handleToggleText = () => {
    setIsVisible(!isVisible);
  };
  const handleToggleTextStudent = () => {
    setIsShow(!isShow);
  };

  const currentUrl = window.location.href;
  return (
    <>
      <div className="homeNav header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | Home</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="wrapper wrapper_main_homePage">
        <div className="header_wraper">
          <div className="header_main">
            <div className="header_img">
              <h1>World-Class Consultancy for Overseas Recruitment Services</h1>
              <h4>
                We are a profesional International Recruiting Agency and
                consulting <br /> firm that can help you with all of your
                priorities and goals.
              </h4>
            </div>
          </div>
          <div className="course_iccons_main_wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 ">
                  <h5>
                    The Migration School Consultancy is the world’s leading
                    consulting company with <br />
                    unparalleled expertise in Overseas recruitment.
                  </h5>
                  <div className="studet_employer_btn">
                    <ScrollLink to={"student_id"} smooth={true} duration={500}>
                      <p>
                        <button>Applicant</button>
                      </p>
                    </ScrollLink>
                    <ScrollLink to={"employer_id"} smooth={true} duration={500}>
                      <p>
                        <button>Employer</button>
                      </p>
                    </ScrollLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="wrapper_main_homePage_Style_fixed">
          <div className="wrapper_mainHome">
            <div className="container">
              <div className=" row wrapper_homePage">
                <div className="col-lg-6">
                  <div className="tmsAbout_content">
                    <h3>About Us</h3>
                    <p>
                      Welcome to The Migration School, your trusted partner in
                      global recruitment and manpower solutions. With a deep
                      commitment to excellence and a passion for connecting
                      talent with opportunities, we specialize in facilitating
                      overseas recruitment for candidates and providing
                      comprehensive workforce solutions for companies across
                      Europe, the Gulf region, Australia, Canada, the UK, and
                      the USA.
                    </p>
                    <RouterLink to={"/About-Us"}>
                      <button className="tms_button_style">About Us</button>
                    </RouterLink>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tmsHome_images">
                    <img src="/Images/tmsHome-image.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="education_section_styleNew" id="student_id">
          <div className="education_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="educat_sect_img">
                    <img src="/Images/karolina-grabowska.jpg" alt="img" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="educat_sect_content">
                    <h5>
                      Explore Global Opportunities with The Migration School
                    </h5>
                    <p>
                      At The Migration School, we are committed to connecting
                      skilled and unskilled workers with exciting job
                      opportunities across the globe. Whether you’re a seasoned
                      professional or just starting your career, we have
                      multiple job openings in various industries waiting for
                      you.
                    </p>
                    <h5>Your Pathway to Global Careers</h5>
                    <p>
                      We specialize in providing access to a wide range of job
                      openings across Europe, the Gulf region, Australia,
                      Canada, the UK, and the USA. Our goal is to help you find
                      the right fit based on your skills, experience, and career
                      aspirations. From construction and healthcare to
                      hospitality, IT, and beyond, we offer opportunities for
                      both skilled trades and unskilled labor positions.
                    </p>
                    <p className="edtech_text">
                      <button
                        className="tms_button_style edtech_student_style"
                        onClick={handleToggleTextStudent}
                      >
                        {isShow ? "Show Less" : "Read More"}
                      </button>

                      {isShow && (
                        <div className="ReadMore_international_content">
                          <h6>How It Works</h6>
                          <p>
                            {" "}
                            <strong>Submit Your Resume: </strong>
                            Start your journey by submitting your resume to us.
                            Our team of experts will review your profile and
                            match your skills with relevant job openings that
                            align with your experience and career goals.
                          </p>
                          <p>
                            {" "}
                            <strong> Personalized Job Recommendations: </strong>
                            Once we receive your resume, we will provide
                            personalized job suggestions that suit your
                            qualifications. Our extensive network of
                            international employers allows us to connect you
                            with the best opportunities in your preferred
                            industry and location.
                          </p>
                          <p>
                            <strong>End-to-End Support: </strong>
                            From the initial job search to the final placement,
                            we offer complete support throughout the recruitment
                            process, including visa assistance, interview
                            preparation, and onboarding guidance.
                          </p>
                          <h6>Why Choose The Migration School?</h6>
                          <p>
                            {" "}
                            <strong>Global Network: </strong>
                            Access job openings in top international markets
                            with reputable employers.
                          </p>
                          <p>
                            {" "}
                            <strong>Comprehensive Job Listings: </strong>
                            We cover a wide range of industries and job types,
                            ensuring there’s something for everyone.
                          </p>
                          <p>
                            {" "}
                            <strong> Expert Career Guidance: </strong> Our
                            dedicated team will guide you at every step, helping
                            you secure the best possible job that fits your
                            skills.
                          </p>
                          <h6>Ready to Take the Next Step?</h6>
                          <p>
                            Submit your resume today and let us help you unlock
                            a world of opportunities. Discover the perfect job
                            that suits your skills and take the next step in
                            your career journey with The Migration School.
                          </p>
                          <p>
                            Submit your resume now to get started, and let us
                            connect you with the right opportunities around the
                            globe!
                          </p>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* International outsourcing agency */}

        <section className="tms_agency_section" id="employer_id">
          <div className="container">
            <div className="tms_international_agency_section">
              <div className="row">
                <div className="col-lg-6">
                  <div className="agency_section_content">
                    <h5> Hire the Best Talent with The Migration School</h5>
                    <p>
                      At The Migration School, we specialize in providing
                      employers with access to a vast pool of qualified
                      candidates from diverse regions, including Nepal, Bhutan,
                      Bangladesh, and beyond. Whether you’re looking for skilled
                      professionals or unskilled labor, we have the right talent
                      to meet your workforce needs.
                    </p>
                    <button
                      className="tms_button_style"
                      onClick={handleToggleText}
                    >
                      {isVisible ? "Show Less" : "Read More"}
                    </button>
                  </div>
                  {isVisible && (
                    <div className="ReadMore_international_content">
                      <h6>Our Services for Employers</h6>
                      <p>
                        We understand that finding the right candidates can be a
                        challenging and time-consuming process. That’s why we
                        are here to simplify it for you. By partnering with The
                        Migration School, employers can easily submit their
                        staffing requirements, and we will take care of the
                        rest, ensuring a seamless recruitment process from start
                        to finish.
                      </p>
                      <p>
                        <strong>Extensive Talent Pool: </strong>
                        We have a large database of candidates with varied
                        skills and experience, ready to work in industries such
                        as construction, healthcare, hospitality, logistics, IT,
                        and more.
                      </p>
                      <p>
                        {" "}
                        <strong>Quick Turnaround: </strong>
                        Once you submit your workforce demand, our team will
                        swiftly identify, screen, and present the most suitable
                        candidates to match your requirements in the shortest
                        possible time.
                      </p>
                      <p>
                        {" "}
                        <strong>Visa & Documentation Support:</strong>
                        We handle all visa formalities, paperwork, and
                        compliance, ensuring that your new hires are fully
                        prepared to join your team without any delays.
                      </p>
                      <p>
                        If you have been thinking about your career options in
                        overseas countries, let us work together to find your
                        best country and job placement. Book an appointment with
                        one of our teams, so we can start to plan your journey
                        from where you are today, to where you want to be.
                      </p>
                      <h6>Why Partner with The Migration School?</h6>
                      <p>
                        <strong>Diverse Candidate Pool:</strong> Access skilled
                        and unskilled workers from various regions, including
                        Nepal, Bhutan, and Bangladesh, with a wide range of
                        expertise.
                      </p>
                      <p>
                        <strong>Tailored Recruitment Solutions: </strong> We
                        offer customized staffing solutions to meet the unique
                        needs of your business, helping you build a reliable and
                        efficient workforce.
                      </p>
                      <p>
                        {" "}
                        <strong>Hassle-Free Hiring:</strong> From candidate
                        sourcing to visa processing, we manage every step of the
                        recruitment process, allowing you to focus on your core
                        business operations.
                      </p>
                      <h6>How It Works</h6>
                      <p>
                        <strong> Submit Your Workforce Demand:</strong> Provide
                        us with your specific hiring requirements, including job
                        roles, skills, and experience levels.
                      </p>
                      <p>
                        <strong>Candidate Matching:</strong> Our team will
                        carefully match your needs with our extensive pool of
                        candidates, ensuring a perfect fit for your company.
                      </p>
                      <p>
                        {" "}
                        <strong> Complete Recruitment Support:</strong> We’ll
                        handle all the necessary documentation, visa
                        formalities, and onboarding processes, making it easy
                        for you to bring in new talent.
                      </p>
                      <h6>Get Started Today</h6>
                      <p>
                        Let us help you build a stronger team with the right
                        talent. Submit your workforce demand, and we will
                        provide you with the best candidates, along with
                        complete visa support, to ensure a smooth hiring
                        process.
                      </p>
                      <p>
                        Partner with The Migration School and discover how we
                        can fulfill your staffing needs efficiently and
                        effectively. Submit your demand now, and let us take
                        care of the rest!
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <div className="agency_sectionImg">
                    <img src="/Images/OFFICE-Images11.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="services_section_HomePage">
          <div className="container">
            <div className="specilized_high_section">
              <h4>Specialized High-Quality Services Include</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="services_section_card_homePage">
                    <p className="services_section_card_homePage_icons">
                      <HiUserGroup />{" "}
                    </p>
                    <h6>Education Consultancy</h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Aperiam dolores dolore, enim hic ipsam eos, nemo
                      laboriosam fugit at aliquam, qui quam beatae sequi quas?
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="services_section_card_homePage">
                    <p className="services_section_card_homePage_icons">
                      <FaSyncAlt />{" "}
                    </p>
                    <h6>HR Sourcing for Employers</h6>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Aperiam dolores dolore, enim hic ipsam eos, nemo
                      laboriosam fugit at aliquam, qui quam beatae sequi quas?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="CareerAndRecruitment_tips_section">
          <div className="container">
            <h3>Quick Career and Recruitments Tips</h3>
            <h6>
              Quick Career and Recruitments Tips to help Candidates and
              employers <br /> find better.
            </h6>
            <div className="main_images_pic">
              <div className="row">
                <div className="col-lg-4">
                  <RouterLink to={'job-vacancies'}>
                    <div className="career_images_cards">
                      <img src="/Images/photo-CarDrive.jpg" alt="" />
                      <div className="images_background_content">
                        <h6>AirPort Jobs</h6>
                        <p>Baggage Handler</p>
                      </div>
                    </div>
                  </RouterLink>
                </div>
                <div className="col-lg-4">
                  <RouterLink to={'job-vacancies'}>
                    <div className="career_images_cards">
                      <img src="/Images/nurcepixabay-images.jpg" alt="" />
                      <div className="images_background_content">
                        <h6>Welder Jobs</h6>
                      </div>
                    </div>
                  </RouterLink>
                </div>
                <div className="col-lg-4">
                  <RouterLink to={'job-vacancies'}>
                    <div className="career_images_cards">
                      <img src="/Images/pranidchakan-boonrom.jpg" alt="" />
                      <div className="images_background_content">
                        <h6>Customer Care Representative</h6>
                      </div>
                    </div>
                  </RouterLink>
                </div>
              </div>
              <div className="Contact_us_link_homePage">
                <div className="contact_usPage_homePage ">
                  <h5>Contact Us For More Information</h5>
                  <p>
                    <RouterLink to="/Contact-Us">
                      <button>Contact Us Now</button>
                    </RouterLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default Home;
