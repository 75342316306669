import React, { useEffect } from "react";
import Header from "../../Component/Header/Header";
import { Helmet } from "react-helmet";
import Footrer from "../../Component/Footer/Footrer";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const FrenchL = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutus_header header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | French </title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="background_image22">
        <img src="/Images/Destination-Reviews-France-1.jpg" alt="image" />
        <div className="bacggroundImgaes_heading_blogPage">
          <p>
            {" "}
            <Link to={"/"}>
              Unlock Your Career in France with{" "}
              <span>
                {" "}
                <IoIosArrowForward /> The Migration School
              </span>{" "}
            </Link>
          </p>
        </div>
      </div>
      <div className="wrapper wrapper_about_us wrapper_ZindexStyle BlogSection_Page">
        <div className="container">
          <div className="jobReddy_programm_Germany">
            <h3>Job Ready Program</h3>
            <p>
              Dreaming of a career in France? The Migration School is here to
              turn that dream into reality. Our job-readiness programs are
              designed to equip you with the language skills and practical
              knowledge needed to secure employment in France.
            </p>
            <p>
              We offer intensive French language training focused on real-world
              job applications. Once you successfully complete your B1 French
              language level, we guarantee 100% job placement in France.
            </p>
            <div className="german_key_highilights">
              <h4>Why Choose Our Program?: </h4>
              <ul>
                <li>
                  <strong>100% Job Placement Guarantee: </strong> Secure a
                  position in France’s healthcare or hospitality industries
                  after completing your B1 level.
                </li>
                <li>
                  <strong>Practical, Job-Oriented Classes: </strong>Learn
                  through hands-on training focused on building skills that
                  employers value.
                </li>
                <li>
                  <strong>Career-Focused Curriculum: </strong>Tailored to help
                  you thrive in the French job market, particularly in
                  healthcare and hospitality.
                </li>
              </ul>
              <p>
                Join us today and take the first step towards a successful
                career in France. Let The Migration School guide you to a
                brighter future!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default FrenchL;
