import React, { useEffect } from "react";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import Header from "../../Component/Header/Header";
import "./PolicyPages.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="main_wrapper_termand_condition">
        <div className="aboutus_header header">
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>TMS | Privacy-Poliicy</title>
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
          <div className="container text_p">
            <p>
              Home <span style={{ marginLeft: "15px" }}>Privacy-Policy</span>
            </p>
          </div>
        </div>
        <div className="wrapper wrapper_AoutUS wrapper_ZindexStyle">
          <div className="container">
            <div className="about_us_content">
              <div className="content_main">
                <h2>Privacy Policy</h2>
                <p>
                  <span style={{ color: "red" }}>The Migration School</span>{" "}
                  ("we," "our," or "us") values your privacy. This Privacy
                  Policy outlines how we collect, use, disclose, and protect
                  your personal information when you interact with our website
                  and services. By using our services, you agree to the terms
                  outlined in this policy.
                </p>
              </div>
              <div className="content_main">
                <h4>Information We Collect</h4>
                <p>
                  We collect personal information to provide educational
                  services related to vocational courses and visa education for
                  multiple countries, including self-visa filing guidance for
                  Australian students. The information we collect includes:
                </p>

                <ul>
                  <li>
                    <strong>Personal Information:</strong>Name, email address,
                    phone number, address, and other identifying details
                    provided during course registration or inquiries.
                  </li>
                  <li>
                    <strong>Payment Information: </strong>Payment details such
                    as credit card information, bank details, or other financial
                    data needed to process your course enrollment.
                  </li>
                  <li>
                    <strong>Technical Information:</strong>Information such as
                    your IP address, browser type, operating system, and usage
                    data collected through cookies or other tracking
                    technologies.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4>How We Use Your Information</h4>
                <p>
                  We use the information we collect for various purposes,
                  including but not limited to:
                </p>
                <ul>
                  <li>
                    <strong>Providing Services:</strong>To facilitate your
                    access to our courses and services, including course
                    registration, payment processing, and personalized
                    educational content.
                  </li>
                  <li>
                    <strong>Communication:</strong>To send you updates,
                    newsletters, promotions, and important notifications about
                    your course progress, new offerings, or changes in our
                    services.
                  </li>
                  <li>
                    <strong>Improving User Experience:</strong>To analyze
                    website traffic, monitor usage patterns, and enhance our
                    website's functionality and content based on user
                    preferences.
                  </li>
                  <li>
                    <strong>Legal and Compliance:</strong>To comply with legal
                    obligations, resolve disputes, and enforce our terms and
                    policies.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> How We Share Your Information</h4>
                <p>
                  We do not sell, rent, or share your personal information with
                  third parties, except in the following circumstances:
                </p>
                <ul>
                  <li>
                    <strong>Service Providers: </strong>We may share your
                    information with trusted third-party service providers who
                    assist us in operating our website, processing payments, or
                    providing other services on our behalf. These providers are
                    bound by confidentiality agreements and are prohibited from
                    using your information for any other purpose.
                  </li>
                  <li>
                    <strong>Legal Requirements: </strong>We may disclose your
                    personal information if required by law or in response to
                    valid legal requests, such as subpoenas or court orders.
                  </li>
                  <li>
                    <strong>Business Transfers: </strong> In the event of a
                    merger, acquisition, or sale of our assets, your information
                    may be transferred to the relevant third party, subject to
                    the continuation of this Privacy Policy.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4>Cookies and Tracking Technologies</h4>
                <p>
                  We use cookies and similar tracking technologies to enhance
                  your experience on our website. These cookies help us
                  understand user behavior, analyze web traffic, and improve
                  functionality. You can control cookie settings in your
                  browser, but disabling cookies may affect your experience on
                  our site.
                </p>
              </div>
              <div className="content_main">
                <h4> Data Security</h4>
                <p>
                  We take data security seriously and implement
                  industry-standard measures to protect your personal
                  information from unauthorized access, disclosure, or
                  alteration. These measures include encryption, secure servers,
                  and restricted access to sensitive data.
                </p>
                <p>
                  However, no method of data transmission or storage is entirely
                  secure. While we strive to protect your personal information,
                  we cannot guarantee absolute security.
                </p>
              </div>
              <div className="content_main">
                <h4> Your Data Rights</h4>
                <p>
                  You have the following rights regarding your personal
                  information:
                </p>
                <ul>
                  <li>
                    <strong>Access:</strong> You may request access to the
                    personal information we hold about you.
                  </li>
                  <li>
                    <strong>Correction:</strong> You may request corrections to
                    any inaccurate or incomplete information.
                  </li>
                  <li>
                    <strong>Deletion: </strong> You may request the deletion of
                    your personal information, subject to legal or regulatory
                    obligations that require us to retain specific data.
                  </li>
                  <li>
                    <strong>Opt-Out:</strong> You may opt out of marketing
                    communications at any time by following the unsubscribe
                    instructions included in our emails or contacting us
                    directly.
                  </li>
                </ul>
                <p>
                  To exercise any of these rights, please contact us at
                 <span style={{color:"red"}}> contact@themigrationschool.com</span>
                </p>
              </div>

              <div className="content_main">
                <h4>Data Retention</h4>
                <p>
                  We retain your personal information for as long as necessary
                  to provide our services, comply with legal obligations,
                  resolve disputes, and enforce our policies. Once your
                  information is no longer required, it will be securely deleted
                  or anonymized.
                </p>
              </div>
              <div className="content_main">
                <h4>Third-Party Links</h4>
                <p>
                  Our website may contain links to third-party websites or
                  services. These third-party websites have their own privacy
                  policies, and we are not responsible for their content or
                  privacy practices. We encourage you to review their policies
                  before providing any personal information.
                </p>
              </div>
              <div className="content_main">
                <h4>Children’s Privacy</h4>
                <p>
                  Our services are not intended for individuals under the age of
                  18. We do not knowingly collect personal information from
                  children. If we discover that we have inadvertently collected
                  information from a child, we will take steps to delete it
                  immediately. If you believe that we have collected such
                  information, please contact us at
                  <span style={{color:"red"}}> contact@themigrationschool.com.</span>
                </p>
              </div>
              <div className="content_main">
                <h4> International Data Transfers</h4>
                <p>
                  As The Migration School provides services to students across
                  multiple countries, your personal information may be
                  transferred to and processed in countries outside of your own.
                  We take appropriate measures to ensure that such data
                  transfers comply with applicable data protection laws.
                </p>
              </div>
              <div className="content_main">
                <h4>Changes to This Privacy Policy</h4>
                <p>
                  We may update this Privacy Policy periodically to reflect
                  changes in our practices, legal requirements, or other
                  factors. The updated policy will be posted on this page with
                  the effective date. We encourage you to review this policy
                  regularly to stay informed of any changes.
                </p>
              </div>
              <div className="content_main">
                <h4>Contact Us</h4>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy, or if you wish to exercise any of your rights, please
                  contact us at:
                </p>
              </div>
            </div>
            <div className="tms_email_and_web">
              <h5 style={{ color: "red" }}>The Migration School</h5>
              <p>
                <strong>Website: </strong>https://themigrationschool.com
              </p>
              <p>
                <strong>Email: </strong>contact@themigrationschool.com
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footrer />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
