import React, { useEffect } from "react";
import "./aboutUs.css";
import Header from "../../Component/Header/Header";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutus_header header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | About Us</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="background_image22">
        <img src="/Images/image-backgroundAboutUs.jpg" alt="" />
        <div className="bacggroundImgaes_heading">
          <h5>About Us</h5>
        </div>
      </div>
      <div className="wrapper wrapper_about_us wrapper_ZindexStyle">
        <div className="container">
          <div className="about_us_content">
            <div className="content_main">
              <p>
                Welcome to The Migration School, your trusted partner in global
                recruitment and manpower solutions. With a deep commitment to
                excellence and a passion for connecting talent with
                opportunities, we specialize in facilitating overseas
                recruitment for candidates and providing comprehensive workforce
                solutions for companies across Europe, the Gulf region,
                Australia, Canada, the UK, and the USA.
              </p>
            </div>
            <div className="content_main">
              <h4>Our Mission</h4>
              <p>
                At The Migration School, our mission is to bridge the gap
                between skilled professionals and international job markets. We
                are dedicated to empowering candidates with the right skills,
                knowledge, and support to successfully navigate their overseas
                career journeys. For businesses, we offer tailored manpower
                solutions that help them thrive in competitive global markets.
              </p>
            </div>
            <div className="content_main">
              <h4>What We Do</h4>
              <ul>
                <li>
                  <strong>Overseas Recruitment:</strong>We assist candidates in
                  securing promising career opportunities in top international
                  destinations. From initial assessment to visa processing and
                  final placement, our team provides end-to-end support
                  throughout the recruitment journey.
                </li>
                <li>
                  <strong>Manpower Solutions: </strong> We deliver full-scale
                  manpower solutions to companies in diverse sectors, ensuring
                  they have access to the best talent available. Our extensive
                  network and industry expertise enable us to match the right
                  candidates with the right roles, optimizing workforce
                  efficiency and productivity.
                </li>
              </ul>
            </div>
            <div className="content_main">
              <h4>Why Choose Us?</h4>
              <ul>
                <li>
                  <strong>Global Network: </strong> With strong connections
                  across multiple countries, we open doors to international job
                  markets and provide access to a wide range of opportunities.
                </li>
                <li>
                  <strong>Expert Guidance: </strong> Our experienced team of
                  recruitment specialists and career advisors are dedicated to
                  guiding candidates through every step of the process, ensuring
                  a seamless transition to their new roles overseas.
                </li>
                <li>
                  <strong>Customized Solutions: </strong> We understand that
                  each client and candidate is unique. Our services are tailored
                  to meet specific needs, delivering personalized solutions that
                  align with your career goals or business objectives.
                </li>
              </ul>
            </div>
            <div className="content_main">
              <h4>Our Values</h4>
              <ul>
                <li>
                  <strong>Integrity: </strong> We are committed to maintaining
                  the highest standards of honesty and transparency in all our
                  interactions.
                </li>
                <li>
                  <strong>Excellence: </strong> Our focus on delivering quality
                  services ensures that both candidates and companies achieve
                  their desired outcomes.
                </li>
                <li>
                  <strong> Empowerment: </strong> We believe in empowering
                  individuals and organizations by providing them with the
                  tools, resources, and opportunities to succeed.
                </li>
              </ul>
            </div>
            <div className="content_main">
              <p>
                Join us at The Migration School and take the next step toward a
                brighter, globally-connected future. Whether you're a candidate
                looking to launch your international career or a company seeking
                the best talent for your operations, we're here to make your
                journey smooth and successful.
              </p>
            </div>
          </div>
          <div className="Contact_us_link_homePage AboutUs_homwPage_link">
            <div className="contact_usPage_homePage ">
              <h5>Contact Us For More Information</h5>
              <p>
                <Link to={"/Contact-Us"}>
                  <button>Contact Us Now</button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default AboutUs;
