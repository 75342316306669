import React, { useEffect } from "react";
import Header from "../../Component/Header/Header";
import { Helmet } from "react-helmet";
import "./services.css";
import { FaPencilAlt } from "react-icons/fa";
import { FaStamp } from "react-icons/fa6";
import { FaDumbbell } from "react-icons/fa";
import { GrSpa } from "react-icons/gr";
import { Link } from "react-router-dom";
import Footrer from "../../Component/Footer/Footrer";

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutus_header header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | Services</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
      </div>
      <div className="background_image22">
        <img src="/Images/green-living.jpg" alt="" />
        <div className="bacggroundImgaes_heading">
          <h5>Services</h5>
        </div>
      </div>
      <div className="wrapper wrapper_servicesMain_topFixed wrapper_ZindexStyle">
        <div className="container">
          <div className="services_page_padding">
            <div className="row ">
              <div className="col-lg-6">
                <div className="card_content">
                  <p className="services_card_icon">
                    <FaPencilAlt />
                  </p>
                  <h5>Language Training</h5>
                  <p>
                    Unlock your full potential with our expert-led language
                    training courses. Whether you aim to excel in German, French, IELTS, PTE, or
                    Interview preparation , we provide personalized coaching tailored to
                    your needs. Our interactive classes, proven techniques, and
                    flexible schedules ensure a transformative learning
                    experience. Achieve fluency, confidence, and success in your
                    language proficiency test. Enroll today and take the first
                    step towards your dream career or academic journey!
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_content">
                  <p className="services_card_icon">
                    <FaStamp />
                  </p>
                  <h5>Counsellor & Visa consultant Training</h5>
                  <p>
                    Kickstart your career with our specialized training program
                    at The Migration School. Gain in-depth knowledge of visa
                    processes, immigration policies, and effective client
                    counseling techniques. Our expert-led courses are designed
                    to equip you with real-world skills, ensuring you become a
                    trusted advisor in the immigration industry. Get hands-on
                    experience, industry insights, and certification to boost
                    your professional credibility. Enroll now and transform your
                    passion into a successful career! 
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_content">
                  <p className="services_card_icon">
                    <FaDumbbell />
                  </p>
                  <h5>
                    Service to Jobseekers: Jobs abroad (Gulf, Europe, North
                    America, South America, Oceania)
                  </h5>
                  <p>
                    Unlock global career opportunities with our expert job
                    placement services. We connect job seekers with top
                    employers across the Gulf, Europe, North America, South
                    America, and Oceania. Our tailored guidance includes resume
                    building, interview preparation, and visa assistance to help
                    you secure your ideal position overseas. Take the next step
                    towards a rewarding international career. Start your journey
                    with us today!
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card_content">
                  <p className="services_card_icon">
                    <GrSpa />
                  </p>
                  <h5>
                    Services to the employers: Complete Recruitment Cycle (From
                    CV selection to employee Visa)
                  </h5>
                  <p>
                    We provide end-to-end recruitment solutions to help you find
                    the right talent for your business. From CV selection to
                    employee visa processing, we manage the entire recruitment
                    cycle, ensuring a seamless experience for employers. Our
                    expert team sources top candidates across various
                    industries, saving you time and effort. Partner with us to
                    hire skilled professionals and grow your workforce
                    efficiently. Let us handle your hiring needs so you can
                    focus on your business!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="Contact_us_link_homePage AboutUs_homwPage_link">
            <div className="contact_usPage_homePage ">
              <h5>Contact Us For More Information</h5>
              <p>
                <Link to={"/Contact-Us"}>
                  <button>Contact Us Now</button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default Services;
