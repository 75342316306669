import React from 'react'

const PaymentFail = () => {
  return (
    <>
      <h1>Payment Fail</h1>
    </>
  )
}

export default PaymentFail
