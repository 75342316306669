import React, { useEffect } from "react";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import Header from "../../Component/Header/Header";
import "./PolicyPages.css"

const TermAndConditions = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="main_wrapper_termand_condition">
        <div className="aboutus_header header">
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>TMS | Terms And Conditions</title>
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
          <div className="container text_p">
            <p>
              Home{" "}
              <span style={{ marginLeft: "15px" }}>Terms And conditions</span>
            </p>
          </div>
        </div>
        <div className="wrapper wrapper_AoutUS wrapper_ZindexStyle">
          <div className="container">
            <div className="about_us_content">
              <div className="content_main">
                <h2>Terms And Conditions</h2>
                <p>
                  Welcome to <span style={{color:"red"}}>The Migration School</span> (referred to as "we," "our,"
                  or "us"). These Terms and Conditions govern your use of our
                  website and services. By accessing our website or enrolling in
                  our courses, you agree to comply with and be bound by these
                  terms.
                </p>
              </div>
              <div className="content_main">
                <h4>General Information</h4>
                <p>
                  The Migration School provides educational services
                  specializing in vocational courses and visa education for
                  multiple countries. We also offer courses specifically
                  designed for Australian students to assist with their visa
                  filing processes.
                </p>
              </div>
              <div className="content_main">
                <h4>Course Enrollment</h4>
                <ul>
                  <li>
                    <strong>Eligibility:</strong>You must be at least 18 years
                    old or have the consent of a guardian to enroll in any
                    course.
                  </li>
                  <li>
                    <strong>Payment: </strong>Course fees must be paid in full at
                    the time of registration. We accept various payment methods,
                    including online payment gateways.
                  </li>
                  <li>
                    <strong>Refund Policy:</strong>Refunds are granted only if
                    the request is made within 7 days of course enrollment, and
                    if less than 10% of the course content has been accessed. No
                    refunds will be provided after this period or under any
                    other circumstances.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> Course Access and Intellectual Property</h4>
                <ul>
                  <li>
                    <strong>Course Access: </strong>Upon successful payment, you
                    will be granted access to the course material. The access
                    duration may vary depending on the course.
                  </li>
                  <li>
                    <strong>Intellectual Property: </strong>All content provided
                    through our courses, including videos, presentations, text,
                    and other materials, are the intellectual property of The
                    Migration School. You may not copy, distribute, or share any
                    content without prior written consent from us.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> Use of Services</h4>
                <ul>
                  <li>
                    <strong>Accuracy of Information: </strong>You agree to provide accurate and up-to-date information during registration and course activities.
                  </li>
                  <li>
                    <strong>Account Security: </strong>You are responsible for maintaining the confidentiality of your login credentials. Unauthorized access or misuse of your account may result in termination of services.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4>Visa Educational Courses</h4>
                <p>
                Our visa educational courses provide guidance on various visa processes. However, we do not offer legal visa services or representation. The courses are designed to educate and empower students but are not a substitute for professional immigration advice.
                </p>
              </div>
              <div className="content_main">
                <h4>  Self-Visa Filing for Australian Students</h4>
                <p>For Australian students taking our self-visa filing courses:</p>
                <ul>
                  <li>
                    <strong>Guidance Only:</strong>These courses are for educational purposes, providing step-by-step instructions on visa applications. The Migration School is not responsible for the outcome of any visa application filed by students.
                  </li>
                  <li>
                    <strong>No Legal Advice: </strong> We do not offer legal advice. Students are encouraged to seek professional consultation for legal or complex immigration matters.
                  </li>
                </ul>
              </div>

              <div className="content_main">
                <h4>Third-Party Resources</h4>
                <p>
                Some courses may include links to third-party websites or resources. We do not endorse or control these resources and are not responsible for their content, products, or services.
                </p>
              </div>
              <div className="content_main">
                <h4>Privacy Policy</h4>
                <p>
                Our Privacy Policy governs how we collect, use, and protect your personal data. By using our services, you consent to the terms outlined in our Privacy Policy.
                </p>
              </div>
              <div className="content_main">
                <h4>Limitation of Liability</h4>
                <p>
                To the extent permitted by law, The Migration School shall not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of the website or services, including delays, errors, or interruptions in the course content.
                </p>
              </div>
              <div className="content_main">
                <h4> Termination</h4>
                <p>
                We reserve the right to terminate or suspend your access to our website or services at any time, without notice, if we believe you have violated these Terms and Conditions.
                </p>
              </div>
              <div className="content_main">
                <h4>Amendments to Terms</h4>
                <p>
                We may update or amend these Terms and Conditions at any time. The updated terms will be posted on this page with the date of the latest revision. Continued use of our services implies acceptance of the updated terms.
                </p>
              </div>
              <div className="content_main">
                <h4>Contact Information</h4>
                <p>
                For any questions regarding these Terms and Conditions, please contact us at:
                </p>
              </div>
            </div>
            <div className="tms_email_and_web">
              <h5 style={{color:"red"}}>The Migration School</h5>
              <p><strong>Website: </strong>https://themigrationschool.com</p>
              <p><strong>Email:  </strong>contact@themigrationschool.com</p>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footrer />
        </div>
      </div>
    </>
  );
};

export default TermAndConditions;
