import React, { useEffect } from "react";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import Header from "../../Component/Header/Header";
import "./PolicyPages.css";

const ShippingPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="main_wrapper_termand_condition">
        <div className="aboutus_header header">
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>TMS | Shipping Policy</title>
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
          <div className="container text_p">
            <p>
              Home <span style={{ marginLeft: "15px" }}>Shipping Policy</span>
            </p>
          </div>
        </div>
        <div className="wrapper wrapper_AoutUS wrapper_ZindexStyle">
          <div className="container">
            <div className="about_us_content">
              <div className="content_main">
                <h2>Shipping Policy</h2>
                <p>
                  <span style={{ color: "red" }}>The Migration School</span>{" "}
                  provides educational services, including vocational and visa
                  educational courses. As we do not offer any physical products,
                  there are no shipping services associated with our offerings.
                </p>
              </div>
              <div className="content_main">
                <h4>No Physical Products or Shipping</h4>
                <p>
                  Since our courses and services are delivered online through
                  digital platforms, there is no need for physical shipping. All
                  course materials, resources, and content are accessible online
                  through our website or via email after successful enrollment.
                </p>
              </div>
              <div className="content_main">
                <h4>Course Access</h4>
                <ul>
                  <li>
                    <strong>Online Courses:</strong>Upon successful payment,
                    students will receive access to their course materials
                    through their account on our website. An email confirmation
                    with login details and course instructions will be sent to
                    the registered email address.
                  </li>
                  <li>
                    <strong>Digital Materials: </strong>Any supplementary
                    materials, such as downloadable resources, PDFs, or
                    presentations, will be made available electronically via the
                    course platform or email.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> Contact Information</h4>
                <p>
                  If you have any questions regarding the delivery of our
                  educational services or need assistance with accessing your
                  course materials, please contact us at:
                </p>
              </div>
            </div>
            <div className="tms_email_and_web">
              <h5 style={{ color: "red" }}>The Migration School</h5>
              <p>
                <strong>Website: </strong>https://themigrationschool.com
              </p>
              <p>
                <strong>Email: </strong>contact@themigrationschool.com
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footrer />
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
