import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./courses.css";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="failure_page55">
        <div className="payment-success-img">
          <img src="/Images/online-payment.avif" alt="payment-image" />
        </div>
        <div className="centered-element">
          <div className="otp_verify">
            <h2>Payment Successfull!</h2>
          </div>
          <Button
            variant="contained"
            size="small"
            className="buton_payment"
            onClick={handleNext}
          >
            OK
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
