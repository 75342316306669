import React from "react";
import "./nav.css";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { PiGraduationCapBold } from "react-icons/pi";
import { TbArmchair2 } from "react-icons/tb";
// ********************NavBar******************
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = () => {
  return (
    <>
      <div className="wrapper header_wraper1">
        <Navbar expand="md" className="bg-body-tertiary mb-3 offCanva_navbar">
          <div className="container">
            <Navbar.Brand href="#">
              <div className="logo">
                <img src="/Images/TMS-Logo.png" alt="Logo" />
                <p className="company_name">
                  {" "}
                  <span style={{ color: "blue" }}>
                    The Migration School
                  </span>{" "}
                </p>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="md" />
            <Navbar.Offcanvas aria-labelledby="md" placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <div className="logo">
                    <img src="/Images/TMS-Logo.png" alt="Logo" />
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavLink to="/" className="nav-link" exact>
                    Home
                  </NavLink>
                  <NavLink to="/About-Us" className="nav-link">
                    About Us
                  </NavLink>
                  <NavLink to="/services" className="nav-link">
                    Services
                  </NavLink>
                  <NavLink to="/job-vacancies" className="nav-link">
                    Job Vacancies
                  </NavLink>
                  <NavLink to="/Courses" className="nav-link">
                    Courses
                  </NavLink>
                  <NavLink to="/german" className="nav-link">
                    German
                  </NavLink>
                  <NavLink to="/french" className="nav-link">
                    French
                  </NavLink>
                  <NavLink to="/Contact-Us" className="nav-link">
                    Contact Us
                  </NavLink>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
